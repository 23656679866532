<template>
  <div class="page__case">
    <y-list-page
      ref="listPageRef"
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <div></div>
        <!-- <el-button @click="add" type="primary">批量导出</el-button> -->
      </template>
    </y-list-page>
  </div>
</template>

<script>
export default {
  name: 'Case',
  data() {
    return {
      model: {
        keyword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '关键词',
            prop: 'keyword'
          }
        }
      ],
      columns: [
        {
          label: '#ID',
          prop: 'id',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '姓名',
          prop: 'name',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '电话',
          prop: 'telephone',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '邮箱',
          prop: 'email',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '身份证号',
          prop: 'idNumber',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '地址',
          render(h, record) {
            if (!record.address && !record.addressDetail) {
              return '-'
            }

            return (record.address || '') + ' ' + (record.addressDetail || '')
          }
        },
        {
          label: '信息是否完整',
          prop: 'replenish',
          render(h, v) {
            return v ? '是' : '否'
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        }
      ]
    }
  },
  methods: {
    fetchFn({ currentPage, ...data }) {
      return this.$serve
        .contestantList({
          data: {
            ...data,
            pageIndex: currentPage
          }
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return {
            ...res,
            page: res.pageIndex
          }
        })
    },
    add() {
      this.$router.push('/case/add')
    },
    toModify(record) {
      this.$router.push(`/case/update/${record.id}`)
    },
    deleteAction(record) {
      this.$confirm('确认删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(record)
        })
        .catch(() => {})
    },
    async delete(record) {
      const res = await this.$serve.caseDelete({
        aimid: record.id
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('删除成功')
      this.$refs.listPageRef.fetchData()
    },
    async check(record) {
      const res = await this.$serve.oss({
        urlQuery: record.contextUrl
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$alert(res, record.title, {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        closeOnClickModal: true,
        customClass: 'page__news-msgbox'
      }).catch(() => {})
    },
    preview(record) {
      window.open(
        `http://moban004.yocsi.com/detail.html?type=case&id=${record.id}`
      )
    }
  }
}
</script>
