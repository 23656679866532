var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__case"},[_c('y-list-page',{ref:"listPageRef",attrs:{"pro-form-style":{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },"form-style":{ textAlign: 'right' },"inline":"","label-width":"100px","model":_vm.model,"fields":_vm.fields,"submitter":{
      submitText: '搜索',
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    },"columns":_vm.columns,"fetch":_vm.fetchFn},scopedSlots:_vm._u([{key:"form-before-addon",fn:function(){return [_c('div')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }